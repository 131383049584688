import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { clear } from "./utils";

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  const expire = localStorage.getItem('expire');
  const navigate = useNavigate();

  useEffect(() => {
    if (token && expire) {
      if (Date.now() >= Number(expire)) {
        clear();
        localStorage.setItem('history', window.location.href);
        toast.error("登录已过期，请重新登录");

        // 延迟跳转
        setTimeout(() => {
          navigate("/login");
        }, 10);
      }
    } else {
      // 如果没有 token，提示并跳转到登录页面
      localStorage.setItem('history', window.location.href);
      toast.error("请先登录");

      // 延迟跳转
      setTimeout(() => {
        navigate("/login");
      }, 10);
    }
  }, [token, expire, navigate]);

  if (token && expire && Date.now() < Number(expire)) {
    return children;  // 只有token有效时才渲染子组件
  }

  return null;  // 在 `useEffect` 触发跳转前不渲染任何东西
}

export { PrivateRoute };
