import React, { useState } from "react";
import Input from "../common/form";
import api from "../common/api";
import toast from "react-hot-toast";
const LoginPage = () => {
    const [isPost, setIsPost] = useState(false);
    const [sendCode, setSendCode] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        username: "",
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (sendCode) {
            e.preventDefault();
            const res = await api("post", "/api/user/forget?cage=check", {
                username: formData.username,
                email: formData.email,
                code: e.target.code.value,
            });
            if (res.status) {
                toast.success("新密码已发送到您的邮箱");
                setTimeout(() => {
                    window.location.href = "/login";
                }, 1500);
            } else {
                toast.error(res.message);
            }
        }
    };

    const send = async (e) => {
        if (isPost) return;
        e.preventDefault();
        //判断是否都为空
        if (!formData.username || !formData.email) {
            toast.error("请填写用户名和邮箱");
            return;
        }
        //判断邮箱格式
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
            toast.error("邮箱格式错误");
            return;
        }
        setIsPost(true);
        // 使用toast.promise包裹API请求
        toast.promise(
            api("post", "/api/user/forget?cage=send", {
                username: formData.username,
                email: formData.email,
                code: "0",
            }),
            {
                loading: '发送验证码中...',
                success: (res) => {
                    if (res.status) {
                        setSendCode(true);
                        setIsPost(false);
                        return "验证码发送成功";
                    } else if (res.message === "请等待十分钟") {
                        setSendCode(true);
                        setIsPost(false);
                        return "验证码未过期，十分钟后可重发";
                    } else {
                        throw new Error(res.message);  // 错误会被catch
                    }
                },
                error: (err) => {
                    setIsPost(false);
                    return err.message;
                }
            }
        );
    };

    return (
        <div className="flex-grow flex items-center justify-center">
            <div className="w-full max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h1 className="text-2xl text-center mb-4">找回密码</h1>
                {!sendCode ? (
                    <>
                        <Input title="用户名" name="username" label="username" type="text" placeholder="Username" onChange={handleChange} />
                        <Input title="邮箱" name="email" label="email" type="email" placeholder="Email" onChange={handleChange} />
                        <button
                            disabled={isPost}
                            onClick={send}
                            className="btn bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            {isPost ? <span className="loading loading-spinner"></span> : "发送验证码"}
                        </button>
                    </>
                ) : (
                    <form
                        onSubmit={handleSubmit}
                    >
                        <Input
                            title="验证码"
                            label="text"
                            type="text"
                            name="code"
                            placeholder="Code"
                        />
                        <button
                            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            重置密码
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default LoginPage;
