import React from "react";
import QRCode from 'qrcode.react';
import Modal from '../../common/modal';
import { changeModal, copy } from "../../common/utils";

const Sub = ({ subLink }) => {
    return (
        <>
            <Modal target={"sub"} title={"订阅方式"}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <button onClick={() => copy(subLink)} className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">复制网址</button>
                    <button onClick={() => { changeModal('qr', true); }}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">查看二维码</button>
                    <button
                        onClick={() => {
                            let processedSubLink = btoa(subLink + "&flag=shadowrocket");
                            window.location.href = `shadowrocket://add/sub://${processedSubLink}?remark=${encodeURIComponent(process.env.REACT_APP_NAME)}`;
                        }}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        导入Shadowrocket
                    </button>
                    <button
                        onClick={() => {
                            let encodeurl = encodeURIComponent(subLink + "&flag=clash");
                            window.location.href = `clash://install-config?url=${encodeurl}&name=${encodeURIComponent(process.env.REACT_APP_NAME)}`;
                        }}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        导入Clash
                    </button>
                    <button
                        onClick={() => {
                            let encodeurl = encodeURIComponent(subLink);
                            window.location.href = `clash://install-config?url=${encodeurl}&name=${encodeURIComponent(process.env.REACT_APP_NAME)}`;
                        }}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        导入Clash Meta
                    </button>
                    <button
                        onClick={() => {
                            let encodeurl = encodeURIComponent(subLink);
                            window.location.href = `stash://install-config?url=${encodeurl}`;
                        }}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        导入Stash
                    </button>
                    <button
                        onClick={() => {
                            let encodeurl = encodeURIComponent(subLink + "&flag=singbox#" + process.env.REACT_APP_NAME);
                            window.location.href = `sing-box://import-remote-profile?url=${encodeurl}`;
                        }}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        导入Sing-Box
                    </button>
                    <button
                        onClick={() => {
                            let encodeurl = encodeURIComponent(subLink + "&flag=surge");
                            window.location.href = `surge://install-config?url=${encodeurl}&name=${encodeURIComponent(process.env.REACT_APP_NAME)}`;
                        }}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        导入Surge
                    </button>
                </div>
            </Modal>
            <Modal target={"qr"} title={"二维码"}>
                <div
                    className="bg-white p-8 rounded-lg relative flex flex-col items-center"
                >
                    <QRCode value={subLink} />
                </div>
            </Modal>
        </>
    )
};
export default Sub;