const Modal = ({ target, title, children }) => {
    return (
        <>
            <input type="checkbox" id={target} className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box">
                    <div className='mb-5'>
                        <form method="dialog">
                            <label htmlFor={target} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
                        </form>
                        <h3 className="font-bold text-lg">{title}</h3>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Modal;