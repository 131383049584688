import React, { useEffect } from "react";
import Windows from "./help/windows";
import Mac from "./help/mac";
import Android from "./help/android";
import IOS from "./help/ios";
import { toast } from "react-hot-toast";
import api from "../common/api";
import { clear } from "../common/utils";

const Tutorial = () => {
    const [subLink, setSubLink] = React.useState("");
    const getUserInfo = () => {
        toast.promise(
            api("post", "/api/user/info", null),
            {
                loading: "正在加载会员信息",
                success: (response) => {
                    if (response.status) {
                        localStorage.setItem('userInfo', JSON.stringify(response.data));
                        // 设置五分钟过期
                        localStorage.setItem('userInfo_expire', Date.now() + 60000);
                        setSubLink(process.env.REACT_APP_LINK + `/sub?token=${response.data.token}`);
                        return "加载成功";
                    } else {
                        if (response.message === "401") {
                            clear();
                            window.location.href = '/login';
                            throw new Error("请重新登录");
                        } else {
                            throw new Error(response.message);
                        }
                    }
                },
                error: (error) => {
                    return error.message;
                }
            }
        );
    };
    useEffect(() => {
        if (localStorage.getItem('userInfo') && localStorage.getItem('userInfo_expire') > Date.now()) {
            const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
            setSubLink(process.env.REACT_APP_LINK + `/sub?token=${storedUserInfo.token}`);
        } else {
            getUserInfo();
        }
    }, []);
    return (
        <main className="py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <section className="mb-12">
                    <h1 className="text-5xl font-bold mb-8 text-center">使用教程</h1>
                    <p className="text-xl text-gray-700 mb-12 text-center">
                        按照以下步骤设置您的订阅服务，并在任何设备上畅享我们的网络加速服务。
                    </p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
                        <label htmlFor="windows" className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold px-6 rounded-lg text-lg text-center focus:outline-none focus:ring-2 focus:ring-blue-400">Windows</label>
                        <Windows
                            subLink={subLink}
                        />
                        <label htmlFor="macos" className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold px-6 rounded-lg text-lg text-center focus:outline-none focus:ring-2 focus:ring-blue-400">macOS</label>
                        <Mac
                            subLink={subLink}
                        />
                        <label htmlFor="android" className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold px-6 rounded-lg text-lg text-center focus:outline-none focus:ring-2 focus:ring-blue-400">Android</label>
                        <Android
                            subLink={subLink}
                        />
                        <label htmlFor="ios" className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold px-6 rounded-lg text-lg text-center focus:outline-none focus:ring-2 focus:ring-blue-400">iOS</label>
                        <IOS
                            subLink={subLink}
                        />
                    </div>
                </section>
                <section className="bg-gray-100 rounded-lg p-8 mb-12">
                    <h2 className="text-3xl font-bold mb-6 text-center">登录并获取订阅地址</h2>
                    <ol className="list-decimal list-inside text-lg text-gray-700 space-y-4">
                        <li>访问我们的网站并使用您的账户登录。</li>
                        <li>进入用户面板后，您可以选择签到以获取每日免费流量，或者选购套餐以获得更多服务。</li>
                        <li>在用户面板中找到您的订阅地址，该地址将在配置客户端时使用。</li>
                    </ol>
                </section>
                <section className="bg-gray-100 rounded-lg p-8 mb-12">
                    <h2 className="text-3xl font-bold mb-6 text-center">相关问题及解答</h2>
                    <div className="text-lg text-gray-700 space-y-4">
                        <h3 className="text-2xl font-bold">常见问题</h3>
                        <p><strong>问：</strong>如何获取订阅地址？</p>
                        <p><strong>答：</strong>登录我们的官方网站，在用户面板中即可找到您的订阅地址。</p>
                        <p><strong>问：</strong>为什么我无法连接到服务器？</p>
                        <p><strong>答：</strong>请确保您输入的订阅地址正确，并尝试更新订阅获取最新的服务器列表。</p>
                        <p><strong>问：</strong>如何更换服务器？</p>
                        <p><strong>答：</strong>在客户端的服务器列表中选择一个新的服务器，并点击连接。</p>
                        <p><strong>问：</strong>流量没用完也没过期，为什么提示用完或过期？</p>
                        <p><strong>答：</strong>订阅地址改变了，需要重新复制面板中的地址。</p>
                    </div>
                </section>
                <section className="bg-gray-100 rounded-lg p-8">
                    <p className="mb-2 text-lg text-gray-700 text-center">如果您有任何疑问或需要帮助，请联系我们的客服。</p>
                    <p className="text-lg text-gray-700 text-center"><strong>Telegram Group:</strong> @{process.env.REACT_APP_TG_GROUP}</p>
                    <p className="text-lg text-gray-700 text-center"><strong>Telegram Channel:</strong> @{process.env.REACT_APP_TG_CHANNEL}</p>
                </section>
            </div>
        </main>
    );
};

export default Tutorial;
