import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    // 如果带有r参数，就跳转到/register?r=r
    const url = new URL(window.location.href);
    const r = url.searchParams.get('r');
    if (r) {
      window.location.href = `/register?r=${r}`;
    }
  }, []);
  return (
    <main className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <section className="mb-12 mt-5">
          <h1 className="text-5xl font-bold mb-8 text-center">
            {process.env.REACT_APP_NAME}，畅游自由的网络世界
          </h1>
          <p className="text-xl text-gray-700 mb-12 text-center">
            欢迎来到{process.env.REACT_APP_NAME}，这里提供安全、可靠、实惠的网络加速服务，让你畅游互联网，突破地域限制，尽享全球信息！
          </p>
          <div className="flex items-center justify-center space-x-4">
            <a
              href="/plan"
              className="bg-blue-500 hover:bg-blue-600 text-white py-4 px-8 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-blue-400">
              立即选购
            </a>
            <a
              href="/dashboard"
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-4 px-8 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-gray-400">
              进入面板
            </a>
          </div>
        </section>
        <section className="bg-gray-100 rounded-lg p-8">
          <h2 className="text-3xl font-bold mb-6 text-center">我们的优势</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <path d="M10 2l8 4v5c0 6-4 9-8 9s-8-4-8-9V6l8-4z"></path>
                <path d="M10 12h4"></path>
                <path d="M12 10v4"></path>
              </svg>
              <h3 className="text-xl font-semibold mb-2">安全可靠</h3>
              <p className="text-gray-700 text-center">
                我们的网络加速服务采用最先进的加密技术，保障您的网络安全与隐私。
              </p>
            </div>
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <path d="M4 12h16"></path>
                <path d="M4 6h12"></path>
                <path d="M4 18h8"></path>
                <path d="M20 6v12"></path>
                <path d="M16 9l4-3 4 3"></path>
              </svg>
              <h3 className="text-xl font-semibold mb-2">高速稳定</h3>
              <p className="text-gray-700 text-center">
                我们的服务器分布全球各地，保证您在任何时间、任何地点都能享受到流畅的网络体验。
              </p>
            </div>
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <rect x="6" y="6" width="12" height="12" rx="2" ry="2"></rect>
                <path d="M9 12l2 2 4-4"></path>
              </svg>
              <h3 className="text-xl font-semibold mb-2">简单易用</h3>
              <p className="text-gray-700 text-center">
                我们提供适配市面上主流的客户端订阅，让您一键连接，轻松畅游互联网。
              </p>
            </div>
            {/* 新增特色第一排 */}
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <path d="M12 20l4-4H8l4 4z"></path>
                <path d="M12 4l4 4H8l4-4z"></path>
                <path d="M2 12h20"></path>
              </svg>
              <h3 className="text-xl font-semibold mb-2">全自研开发</h3>
              <p className="text-gray-700 text-center">
                所有技术均为自主研发，确保高效、安全和持续创新。
              </p>
            </div>
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="9" cy="10" r="1"></circle>
                <circle cx="15" cy="10" r="1"></circle>
                <path d="M8 16a5 4 0 0 0 8 0"></path>
              </svg>
              <h3 className="text-xl font-semibold mb-2">海外团队运营</h3>
              <p className="text-gray-700 text-center">
                由全球各地的运营团队提供支持，确保您享受无忧的服务体验。
              </p>
            </div>

            {/* 新增特色第二排 */}
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <path d="M3 12h18"></path>
                <path d="M12 3v18"></path>
                <path d="M8 12h8"></path>
              </svg>
              <h3 className="text-xl font-semibold mb-2">直连线路</h3>
              <p className="text-gray-700 text-center">
                直连海外，确保数据传输的高速与稳定，减少延迟。
              </p>
            </div>
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M8 12l4-4 4 4"></path>
                <path d="M12 8v8"></path>
              </svg>
              <h3 className="text-xl font-semibold mb-2">中转线路</h3>
              <p className="text-gray-700 text-center">
                通过国内入口到达海外服务器，优化跨境访问体验。
              </p>
            </div>
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(4, 70, 222)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-16 w-16 mb-4"
              >
                <circle cx="3" cy="12" r="2"></circle>
                <circle cx="21" cy="12" r="2"></circle>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              <h3 className="text-xl font-semibold mb-2">专线线路</h3>
              <p className="text-gray-700 text-center">
                提供IEPL专线线路，确保网络的质量与稳定。
              </p>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Home;
