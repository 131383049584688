import React from 'react';
import { toast } from 'react-hot-toast';
import api from '../../common/api';
import Modal from '../../common/modal';
import { changeModal } from '../../common/utils';

const Passwd = () => {
    const handlePass = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // 检查都不能为空
        if (!formData.get('opass') || !formData.get('pass') || !formData.get('rpass')) {
            toast.error("请填写完整信息");
            return;
        }
        // 检查两次密码是否一致
        if (formData.get('pass') !== formData.get('rpass')) {
            toast.error("两次密码输入不一致");
            return;
        }
        try {
            const response = await api("post", "/api/user/password", formData);
            if (response.message === "401") {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                if (response.status) {
                    toast.success("修改成功");
                    changeModal('passwd', false);
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 1500);
                } else {
                    toast.error(response.message);
                }
            }
        } catch (error) {
            toast.error("网络错误，请重试");
        }
    }
    return (
        <Modal target="passwd" title="修改密码">
            <form
                onSubmit={handlePass}
            >
                <input name="opass" placeholder="请输入原密码" type="password" className="input w-full border border-gray-300 rounded-md p-2" />
                <input name="pass" placeholder="请输入新密码" type="password" className="input w-full border border-gray-300 rounded-md p-2 mt-2" />
                <input name="rpass" placeholder="请再次输入新密码" type="password" className="input w-full border border-gray-300 rounded-md p-2 mt-2" />
                <button type="submit" className="btn w-full my-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">修改</button>
            </form>
        </Modal>
    );
}

export default Passwd;