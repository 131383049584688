import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import api from "../common/api";

function Pay() {
    const getPay = async () => {
        const queryParams = window.location.search; // 获取当前URL的查询参数
        await api("get", `/api/user/pay/notify${queryParams}`);
    };
    useEffect(() => {
        localStorage.removeItem("userInfo");
        getPay();
    }, []);
    return (
        <div className="min-h-80 flex items-center justify-center bg-gray-100">
            <div className="max-w-md text-center">
                <h2 className="text-4xl font-bold text-gray-800 mb-4">支付成功</h2>
                <p className="text-lg text-gray-600 mb-6">感谢您的购买,有任何问题可联系右下角客服或TG群管理</p>
                <Link
                    to="/dashboard"
                    className="block px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                    返回控制面板
                </Link>
            </div>
        </div>
    );
}

export default Pay;