import React from "react";
import ModalConfirm from "../../common/modalConfirm";
import api from "../../common/api";
import toast from "react-hot-toast";
import { changeModal } from "../../common/utils";

const ChangeSub = ({ getUserInfo }) => {
    const exToken = async () => {
        toast.promise(
            api("post", "/api/user/extoken", null),
            {
                loading: "正在修改订阅地址",
                success: (response) => {
                    if (response.message === "401") {
                        localStorage.clear();
                        window.location.href = '/login';
                        throw new Error("请重新登录");
                    } else {
                        if (response.status) {
                            getUserInfo();
                            changeModal('changeSub', false);
                            return "修改成功";
                        } else {
                            throw new Error(response.message);
                        }
                    }
                },
                error: (error) => {
                    return error.message;
                }
            }
        );
    };

    return (
        <ModalConfirm
            target={"changeSub"}
            title={"修改订阅地址"}
            onConfirm={exToken}
        >
            您确认要修改订阅地址吗？<br />
            这会导致您的订阅地址变更。<br />
            原有订阅地址将失效，需要重新导入节点。
        </ModalConfirm>
    );
};
export default ChangeSub;