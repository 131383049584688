import React from "react";
import Modal from '../../common/modal';

const Tips = () => {
    return (
        <Modal target={"tips"} title={"使用须知"}>
            <div className="space-y-1">
                <ul className="list-disc list-inside text-lg font-medium text-gray-900">
                    <li>每日签到可获得0-1GB流量</li>
                    <li>签到或购买套餐后会出现订阅按钮</li>
                    <li>未购买套餐用户将会进行限速处理</li>
                    <li>节点全超时需等待三分钟同步信息</li>
                    <li>建议绑定邮箱以接收服务通知</li>
                </ul>
            </div>
            <button
                onClick={() => {
                    const tipsCheckbox = document.getElementById('tips');
                    if (tipsCheckbox) {
                        tipsCheckbox.checked = false;
                    }
                    localStorage.removeItem('register');
                }}
                className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            >
                我已知晓
            </button>
        </Modal>
    );
};
export default Tips;