// ChangeEmail.js
import React from 'react';
import toast from "react-hot-toast";
import api from '../../common/api';
import Modal from '../../common/modal';


const ChangeEmail = ({ userInfo, setUserInfo }) => {
    const handleChangeEmail = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // 检查邮箱是否为空
        if (!formData.get('email')) {
            toast.error("请填写邮箱");
            return;
        }
        try {
            const response = await api("post", "/api/user/email", formData);
            if (response.message === "401") {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                if (response.status) {
                    toast.success("修改成功");
                    setUserInfo({ ...userInfo, email: formData.get('email') });
                    // 更新本地缓存
                    localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, email: formData.get('email') }));
                } else {
                    toast.error(response.message);
                }
            }
        } catch (error) {
            toast.error("网络错误，请重试");
        }
    };
    return (
        <Modal target="changeEmail" title="修改邮箱">
            <form onSubmit={handleChangeEmail}>
                <p className="mb-4 text-gray-600">邮箱将用于找回密码和接收套餐到期通知。</p>
                <p className="mb-4 text-gray-600">当前邮箱：{userInfo.email || '待绑定'}</p>
                <label className="input input-bordered flex items-center gap-2 my-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70">
                        <path
                            d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                        <path
                            d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                    </svg>
                    <input type="email" name="email" className="grow" placeholder="请输入新邮箱" />
                </label>
                <button
                    type="submit"
                    className="btn w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    修改
                </button>
            </form>
        </Modal>
    );
};

export default ChangeEmail;