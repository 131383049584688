import React from 'react';
import { toast } from 'react-hot-toast';
import api from '../../common/api';
import { formatBytes, changeModal } from '../../common/utils';
import Modal from '../../common/modal';

const Sign = ({ userInfo, setUserInfo, getUserInfo }) => {
    const handleSign = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        try {
            const response = await api("get", "/api/user/sign?v=" + formData.get('captcha'), null);
            if (response.message === "401") {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                if (response.message === "签到成功") {
                    toast.success("签到成功，获得" + formatBytes(response.data) + "流量");
                    if (userInfo.able === 0) {
                        getUserInfo();
                    } else {
                        setUserInfo({ ...userInfo, traffic: userInfo.traffic + response.data });
                    }
                    changeModal('sign', false);
                } else {
                    toast.error(response.message);
                    // 刷新验证码
                    const captchaImage = document.getElementById("captcha");
                    captchaImage.src = "/captcha?v=" + Math.random();
                    changeModal('sign', false);
                }
            }
        } catch (error) {
            toast.error("网络错误，请重试");
            // 刷新验证码
            const captchaImage = document.getElementById("captcha");
            captchaImage.src = "/captcha?v=" + Math.random();
        }
    };
    return (
        <Modal target={"sign"} title={"签到"}>
            <div className="text-sm text-gray-500 mb-1">签到状态每日八点重置</div>
            <div className="text-sm text-gray-500 mb-1">每日签到可获得0-1GB流量</div>
            <div className="text-sm text-gray-500 mb-1">套餐过期或流量用完签到会导致套餐丢失</div>
            <div className="text-sm text-gray-500 mb-3">可绑定TG Bot(@{process.env.REACT_APP_TG_BOT})自助签到</div>
            <form onSubmit={handleSign}>
                <img
                    src={`/captcha`}
                    id="captcha"
                    alt="captcha"
                    onClick={() => {
                        document.getElementById('captcha').src = `/captcha?v=${Math.random()}`
                    }}
                    className="w-full border border-gray-300 rounded-md p-2 mb-4"
                />
                <input
                    name="captcha"
                    placeholder="请输入验证码"
                    type="text"
                    className="input w-full border border-gray-300 rounded-md p-2 mb-4"
                />
                <button
                    type="submit"
                    className="btn w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    签到
                </button>
            </form>
        </Modal>
    );
}

export default Sign;