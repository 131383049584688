import React from 'react';
import Modal from './modal';

const modalConfirm = ({ target, title, onConfirm, children }) => {
    const close = () => {
        const modalConfirmCheckbox = document.getElementById(target);
        if (modalConfirmCheckbox) {
            modalConfirmCheckbox.checked = false;
        }
    }
    return (
        <Modal target={target} title={title}>
            <div className="space-y-1">
                <p className="text-lg font-medium text-gray-900">{children}</p>
            </div>
            <div className="flex justify-between">
                <button
                    onClick={() => {
                        onConfirm();
                    }}
                    className="mt-6 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                    确认
                </button>
                <button
                    onClick={() => { close(); }}
                    className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    取消
                </button>
            </div>
        </Modal>
    );
};

export default modalConfirm;