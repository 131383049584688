// Recharge.js
import React, { useState } from 'react';
import Modal from '../../common/modal';
import { toast } from 'react-hot-toast';
import api from '../../common/api';

const Recharge = ({ getUserInfo }) => {
    const [amount, setAmount] = useState('');
    const [isPost, setIsPost] = useState(false);
    const [selectedTab, setSelectedTab] = useState('online');
    const [code, setCode] = useState('');
    const getPay = async () => {
        // 判断金额
        if (isPost) return;
        if (amount < 1) {
            toast.error("充值金额不能小于1");
            return;
        }
        if (amount > 1000) {
            toast.error("充值金额不能大于1000");
            return;
        }
        setIsPost(true);
        toast('正在发起支付', {
            icon: '👏',
        });
        const formData = new FormData();
        formData.append("amount", amount);
        const res = await api("post", "/api/user/pay", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (res.status) {
            toast.success("正在跳转支付页面");
            //window.location.href = res.data;
            const newWindow = window.open(res.data, '_blank');
            setIsPost(false);
            if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                window.location.href = res.data;
            }
        } else {
            setIsPost(false);
            toast.error(res.message);
        }
    }
    const handleOnlineRecharge = (e) => {
        e.preventDefault();
        getPay();
    };
    const handleRecharge = (e) => {
        if (isPost) return;
        e.preventDefault();
        // 判断是否为空
        if (!code) {
            toast.error("请输入充值码");
            return;
        }
        const formData = new FormData(e.target);
        setIsPost(true);
        toast.promise(
            // 发起充值请求
            api("post", "/api/user/recharge", formData),
            {
                loading: "兑换中",
                success: (response) => {
                    if (response.message === "401") {
                        localStorage.clear();
                        window.location.href = '/login';
                        throw new Error("未授权，正在跳转到登录页面");  // 强制终止后续流程，避免显示成功提示
                    } else {
                        if (response.status) {
                            getUserInfo();  // 刷新用户信息
                            const rechargeCheckbox = document.getElementById('recharge');
                            if (rechargeCheckbox) {
                                rechargeCheckbox.checked = false;
                            }
                            setIsPost(false);
                            return "充值成功";  // 成功后的提示
                        } else {
                            setIsPost(false);
                            throw new Error(response.message);  // 充值失败，抛出错误
                        }
                    }
                },
                error: (error) => {
                    setIsPost(false);
                    return error.message;  // 捕获错误，返回错误消息
                }
            }
        );
    };
    return (
        <Modal target="recharge" title="余额充值">
            {process.env.REACT_APP_CODE === 'true' && (
                <div role="tablist" className="tabs tabs-boxed mb-5">
                    <a role="tab" className={`tab ${selectedTab === 'code' ? 'tab-active' : ''}`} onClick={() => setSelectedTab('code')}>
                        充值码兑换
                    </a>
                    <a role="tab" className={`tab ${selectedTab === 'online' ? 'tab-active' : ''}`} onClick={() => setSelectedTab('online')}>
                        在线充值
                    </a>
                </div>
            )}
            {selectedTab === 'code' && (
                <form onSubmit={handleRecharge}>
                    <input
                        placeholder="请输入充值码"
                        name="code"
                        type="text"
                        className="input w-full border border-gray-300 rounded-md p-3 mb-4"
                        autoComplete="off"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <div className="flex justify-between">
                        <button
                            type="submit"
                            disabled={isPost}
                            className="btn w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            {isPost ? "..." : "兑换"}
                        </button>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://shop.vpnpn.com/"
                            className="btn w-1/2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                        >
                            购买充值码
                        </a>
                    </div>
                </form>
            )}
            {selectedTab === 'online' && (
                <form onSubmit={handleOnlineRecharge}>
                    <input
                        placeholder="请输入充值金额"
                        name="amount"
                        type="text"
                        className="input w-full border border-gray-300 rounded-md p-3 mb-4"
                        autoComplete="off"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <div className="flex justify-between">
                        <button
                            type="submit"
                            className="btn w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            {isPost ? <span className="loading loading-spinner"></span> : "提交订单"}
                        </button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default Recharge;