import React, { useEffect } from "react";
import Modal from '../../common/modal';
import api from "../../common/api";

const Tutorial = ({ subLink }) => {
    const getplanInfo = async () => {
        try {
            const response = await api("get", "/api/user/appleid", null);
            if (response.message === "401") {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                const ad = document.getElementById("appleid");
                if (ad) ad.innerHTML = response.data;
            }
        } catch (error) {
            const ad = document.getElementById("appleid");
            if (!error || !error.data) {
                ad.innerHTML = "网络错误，无法获取Apple ID";
            }
        }
    };
    useEffect(() => {
        getplanInfo()
    }, []);
    return (
        <Modal target={"ios"} title={"使用教程"}>
            <main className="py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <section className="mb-12">
                        <p className="text-xl text-gray-700 mb-12 text-center">
                            按照以下步骤在 iOS 上设置并使用。
                        </p>
                        <div className="grid grid-cols-1 gap-8 mb-12">
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 1: 下载</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    安装 Shadowrocket(小火箭)<br />
                                    需要美区 Apple ID
                                </p>
                                <a href="https://apps.apple.com/us/app/shadowrocket/id932747118" target="_blank" className="text-blue-500 hover:underline">
                                    下载链接
                                </a>
                            </div>
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">共享Apple ID</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    <p id="appleid">【购买套餐后此处将显示共享Apple ID】</p>
                                    <a href="https://www.taphip.com/appleid.jpg" target="_blank" className="text-blue-500 ">【点我查看图文教程】</a><br />
                                    ❗️登录选择其他选项不升级❗️<br />
                                    ❗️从商店登陆❗️<br />
                                    ❗️不要登陆iCloud❗️
                                </p>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 2: 导入</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    <button
                                        onClick={() => {
                                            let processedSubLink = btoa(subLink + "&flag=shadowrocket");
                                            window.location.href = `shadowrocket://add/sub://${processedSubLink}?remark=${encodeURIComponent(process.env.REACT_APP_NAME)}`;
                                        }}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        一键导入节点
                                    </button>
                                </p>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 3: 运行</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    选择一个节点，点击右上角的开关按钮即可连接
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </Modal>
    );
};

export default Tutorial;