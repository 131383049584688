import React from 'react';
import { toast } from 'react-hot-toast';
import api from '../../common/api';
import { copy } from '../../common/utils';
import Modal from '../../common/modal';

const Recom = ({ userInfo, getUserInfo }) => {
    const exAff = async () => {
        try {
            const response = await api("post", "/api/user/exaff", null);
            if (response.message === "401") {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                if (response.status) {
                    getUserInfo();
                    toast.success("已兑换为余额");
                } else {
                    toast.error(response.message);
                }
            }
        } catch (error) {
            toast.error("网络错误，请重试");
        }
    }
    return (
        <Modal target="recom" title="邀请好友">
            <div className="space-y-4">
                <div>
                    <div className="text-lg font-medium text-gray-900">您的邀请链接</div>
                    <div className="text-sm text-gray-500">{process.env.REACT_APP_LINK + `/?r=${userInfo.id}`}</div>
                </div>
                <div>
                    <div className="text-lg font-medium text-gray-900">邀请规则</div>
                    <div className="text-sm text-gray-500">被邀请者注册可获得1GB不限时流量</div>
                    <div className="text-sm text-gray-500">您可获得被邀请者每次充值金额的{userInfo.aff_rate}%作为奖励</div>
                    <div className="text-sm text-gray-500">奖励金额满100可联系客服进行USDT或支付宝提现</div>
                </div>
                <div>
                    <div className="text-lg font-medium text-gray-900">邀请奖励</div>
                    <div className="text-sm text-gray-500">已获得奖励：{userInfo.aff}￥</div>
                </div>
            </div>
            <div className="mt-5 flex justify-between">
                <button onClick={() => copy(process.env.REACT_APP_LINK + `/?r=${userInfo.id}`)} className="btn w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">复制链接</button>
                <button onClick={exAff} className="btn w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">兑换为余额</button>
            </div>
        </Modal>
    );
};

export default Recom;