import React, { useState, useEffect } from "react";
import Input from "../common/form";
import api from "../common/api";
import toast from "react-hot-toast";
const LoginPage = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const usernameFromUrl = params.get('u');
  const [isPost, setIsPost] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    formData.username = usernameFromUrl;
  }, []);
  const handleSubmit = (e) => {
    if (isPost) return;
    e.preventDefault();
    // 判断是否都为空
    if (!formData.username || !formData.password) {
      toast.error("请填写用户名和密码");
      return;
    }
    setIsPost(true);
    toast.promise(
      api("post", "/api/user/login", formData),
      {
        loading: "登录中...",
        success: (response) => {
          if (response.status) {
            localStorage.setItem("token", response.data);
            localStorage.setItem("expire", Date.now() + 1000 * 60 * 60 * 2);
            if (localStorage.getItem("history")) {
              const history = localStorage.getItem("history");
              localStorage.removeItem("history");
              setTimeout(() => {
                window.location.href = history;
              }
                , 1000);
            } else {
              setTimeout(() => {
                window.location.href = "/dashboard";
              }, 1000);
            }
            return "登录成功";
          } else {
            setIsPost(false);
            throw new Error("登录失败：" + response.message);  // 手动抛出错误，以便显示失败提示
          }
        },
        error: (error) => {
          // 错误处理，返回失败信息
          setIsPost(false);
          return error.message;
        }
      }
    );
  };
  return (
    <div className="flex-grow flex items-center justify-center">
      <div className="w-full max-w-md">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h1 className="text-2xl text-center mb-4">登录</h1>
          <Input
            title="用户名"
            name="username"
            onChange={handleChange}
            label="username"
            type="text"
            placeholder="Username"
            value={usernameFromUrl}
          />
          <Input
            title="密码"
            label="password"
            type="password"
            name="password"
            onChange={handleChange}
            placeholder="Password"
          />
          <div className="flex items-center justify-between mb-4">
            <button
              className="btn bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isPost}
            >
              {isPost ? <span className="loading loading-spinner"></span> : "登录"}
            </button>
            <a
              href="/forget"
              className="text-sm text-gray-600 hover:text-gray-800"
            >
              忘记密码？
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
