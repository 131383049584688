// utils.js

/**
 * Converts bytes to a human-readable string with appropriate units.
 *
 * @param {number} bytes - The number of bytes to convert.
 * @returns {string} - The converted value as a human-readable string.
 */
import { toast } from 'react-hot-toast';
import api from './api';
export function formatBytes(bytes) {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(k)), sizes.length - 1);
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
  return `${size} ${sizes[i]}`;
}
export function convertTimestamp(timestamp) {
  if (timestamp === 0) {
    return "永久有效";
  }
  var date = new Date(timestamp * 1000);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = "0" + date.getMinutes();
  var seconds = "0" + date.getSeconds();
  var formattedTime = `${year}-${month}-${day} ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;
  return formattedTime;
}

export function copy(url) {
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  var textArea = document.createElement("textarea");
  textArea.value = url;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    var successful = document.execCommand('copy');
    var msg = successful ? '复制成功' : '复制失败';
    toast.success(msg);
  } catch (err) {
    toast.error('无法复制');
  }
  document.body.removeChild(textArea);
  // 复制操作完成后，将滚动位置设置回原来的位置
  window.scrollTo(0, scrollPosition);
}

export function changeModal(target, bool) {
  const modalCheckbox = document.getElementById(target);
  if (modalCheckbox) {
    modalCheckbox.checked = bool;
  }
}

export function clear() {
  const refValue = localStorage.getItem('referrer');
  localStorage.clear();
  if (refValue !== null) {
    localStorage.setItem('referrer', refValue);
  }
}