import React from 'react';
import ModalConfirm from '../../common/modalConfirm';
import { changeModal } from '../../common/utils';
import toast from "react-hot-toast";
import api from '../../common/api';

const RePlan = ({ userInfo, getUserInfo }) => {
    const resetPlan = async () => {
        toast.promise(
            api("post", "/api/user/plan", { planId: userInfo.plan }),
            {
                loading: "正在重置计划...",
                success: (response) => {
                    if (response.message === "401") {
                        localStorage.clear();
                        window.location.href = '/login';
                        throw new Error("请重新登录");
                    } else {
                        if (response.status) {
                            getUserInfo();
                            return "重置成功";
                        } else {
                            throw new Error(response.message);
                        }
                    }
                },
                error: (error) => {
                    return error.message;
                }
            }
        );
        changeModal('rePlan', false);
    };

    return (
        <ModalConfirm
            target={'rePlan'}
            title="确认重置"
            onConfirm={resetPlan}
        >
            您确认要重置套餐吗？<br />
            这会导致您重新订购当前套餐。<br />
        </ModalConfirm>
    );
};

export default RePlan;